.level-up {
  i {
    color: #3ace5a;
    transform: rotate(90deg);
  }

  p {
    color: #3ace5a !important;
  }
}

.sort-direction {
  p {
    position: relative;
    width: min-content;
  }

  i {
    position: absolute;
    left: calc(100% + 0.5rem);
  }
}

.bulletin-datatable {
  tbody {
    td:not(:last-child) {
      white-space: normal;
    }
  }
}

.bulletin-status-badge {
  border-radius: 4px;
  text-align: center;
  padding: 4px 12px;
  white-space: nowrap;

  &#{&}-approved {
    background: rgba(32, 161, 68, 0.1);
    color: #20a144;
  }

  &#{&}-waiting {
    background: rgba(81, 128, 251, 0.1);
    color: #5180fb;
  }

  &#{&}-created {
    background: rgba(149, 35, 117, 0.1);
    color: #952375;
  }

  &#{&}-canceled {
    background: rgba(229, 110, 25, 0.1);
    color: #e56e19;
  }

  &#{&}-rejected {
    background: rgba(246, 78, 96, 0.1);
    color: #f64e60;
  }
}

.truncate-custom {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  width: 200px !important;
  word-break: break-all;
}
